import { useState, useRef } from "react";
import {
  ScheduleComponent,
  Month,
  Inject,
  ViewsDirective,
  ViewDirective,
} from "@syncfusion/ej2-react-schedule";
import { formatDate } from "../../helpers/FormatDate";
import { useQuery } from "@apollo/client";
import {
  GET_TIMEZONES,
  GET_PROFILE,
  GET_EMPLOYEE_AVAILABILITY,
} from "../../api/gqlQueries";
import SearchDropdown from "../../helpers/SearchDropdown";
import ErrorMessage from "../errors/ErrorMessage";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Switch,
  Typography,
  Slider,
  Box,
  CircularProgress,
} from "@mui/material";
import { employeeAvailabilityTransformer } from "../calendar/Transformer";
import { userVar } from "../../cache";

interface AvailabilityData {
  availability: boolean;
  startTime: number;
  endTime: number;
}
interface BackendData {
  [date: string]: AvailabilityData;
}
interface AvailabilityProps {
  toggleAvailabilityForm: () => void;
}

//Takes in total number of minutes and will convert to time string in format of 00:00
const formatTime = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}`;
};

const Availability = (props: AvailabilityProps) => {
  const user = userVar();
  const { toggleAvailabilityForm } = props;
  const today = new Date();
  const [month, setMonth] = useState(today.getMonth() + 1);
  const [year, setYear] = useState(today.getFullYear());
  const scheduleRef = useRef<ScheduleComponent | null>(null);
  const [availability, setAvailability] = useState<BackendData>({});
  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  );

  const { loading: profileLoading, error: profileError } = useQuery(
    GET_PROFILE,
    {
      onCompleted(d) {
        const user = d.me;
        if (user.timezone) {
          setTimezone(user.timezone);
        }
      },
    },
  );

  const {
    data: tzData,
    loading: tzLoading,
    error: tzError,
  } = useQuery(GET_TIMEZONES);

  const {
    data: availabilityData,
    loading: availabilityLoading,
    error: availabilityError,
  } = useQuery(GET_EMPLOYEE_AVAILABILITY, {
    variables: {
      employeeId: parseInt(user.id),
      month: month,
      year: year,
    },
    onCompleted(data) {
      if (data?.employeeAvailability) {
        let transformedData: BackendData = employeeAvailabilityTransformer(
          data.employeeAvailability,
        );
        setAvailability(transformedData);
      }
    },
  });

  //Displays preexisting start and end times if it existed, otherwise default to all day. Also toggles the availability boolean
  const toggleAvailability = (date: string) => {
    setAvailability((prevData: any) => {
      const existingEntry = prevData[date] || {};
      let updatedAvailability = !prevData[date]?.availability;
      return {
        ...prevData,
        [date]: {
          availability: updatedAvailability,
          startTime: existingEntry.startTime || 0, // Set to 0 minutes only if not already set
          endTime: existingEntry.endTime || 0,
        },
      };
    });
  };

  //Customize what gets rendered on each cell
  const CellTemplate = ({ date }: any) => {
    const env = { hourCycle: "h23" };
    const cellDate = date;
    const formattedDate = formatDate(cellDate, env);
    const startTime = availability[formattedDate.toString()]?.startTime;
    const endTime = availability[formattedDate.toString()]?.endTime;

    const scheduleInstance = scheduleRef?.current?.activeView as any;

    const [sliderValue, setSliderValue] = useState(
      endTime ? [startTime, endTime] : [0, 1440],
    );

    //Do not display toggle if the day is not in the current view's month
    if (scheduleInstance) {
      let month = scheduleInstance.monthDates.start.getMonth() + 1;
      if (cellDate.getMonth() + 1 !== month) {
        return <></>;
      }
    }

    const isAvailable: boolean =
      availability[formattedDate.toString()]?.availability;

    //Stores the time values once slider stops moving
    const handleCommitChange = (event: any, newValue: any) => {
      setAvailability((prevData: any) => {
        return {
          ...prevData,
          [formattedDate.toString()]: {
            ...prevData[formattedDate.toString()],
            startTime: newValue[0],
            endTime: newValue[1],
          },
        };
      });
    };

    //Updates the sliders value
    const handleChange = (event: any, newValue: any) => {
      setSliderValue(newValue);
    };

    return (
      <Grid container justifyContent="center">
        <FormControl component="fieldset">
          <FormGroup
            onClick={() => toggleAvailability(formattedDate.toString())}
          >
            <FormControlLabel
              control={<Switch checked={isAvailable} />}
              label=""
              data-testid={`toggle-${formattedDate}`}
            />
          </FormGroup>
        </FormControl>
        {isAvailable && (
          <>
            <Box sx={{ width: "75%" }}>
              <Slider
                data-testid={`slider-${formattedDate}`}
                value={sliderValue}
                onChangeCommitted={handleCommitChange}
                onChange={handleChange}
                min={0}
                max={24 * 60}
                step={60}
                valueLabelFormat={(value) => formatTime(value)}
              />
            </Box>

            {`${formatTime(sliderValue[0])} - ${formatTime(sliderValue[1])}`}
          </>
        )}
      </Grid>
    );
  };

  const onCellRender = (args: any) => {
    //Only target the cells, not the workday cells to prevent the weekday rows from being affected
    if (args.elementType === "monthCells") {
      const cellDate = args.date;
      const formattedDate = formatDate(cellDate, {
        hourCycle: "h23",
      }).toString();

      if (availability[formattedDate]?.availability) {
        args.element.style.backgroundColor = "#9cbb90";
      } else {
        args.element.style.backgroundColor = "#9f9393";
      }
    }
  };

  const handleSubmit = () => {
    //Filter out the dates where availability is false
    const availableDates = Object.fromEntries(
      Object.entries(availability).filter(
        ([date, data]) => data.availability === true,
      ),
    );

    alert(`Availability: ${JSON.stringify(availableDates)}
    Timezone: ${JSON.stringify(timezone)}`);
  };

  const handleActionComplete = (args: any) => {
    if (args.requestType === "dateNavigate") {
      const scheduleInstance = scheduleRef?.current?.activeView as any;
      if (scheduleInstance) {
        let month = scheduleInstance.monthDates.start.getMonth() + 1;
        let year = scheduleInstance.monthDates.start.getFullYear();
        setYear(year);
        setMonth(month);
      }
    }
  };

  if (tzLoading || profileLoading || availabilityLoading) {
    return <CircularProgress color="primary" />;
  }
  if (tzError || profileError || availabilityError) {
    return <ErrorMessage />;
  }
  return (
    <Grid container>
      <Grid item xs={1}>
        <IconButton
          aria-label="close"
          color="secondary"
          size="small"
          onClick={toggleAvailabilityForm}
          data-testid="close-availability-form"
        >
          <CloseIcon />
        </IconButton>
      </Grid>

      <Grid item xs={6}></Grid>

      <Grid item xs={2}>
        <SearchDropdown
          options={tzData?.getTimezones.map((timezone: string) => ({
            label: timezone,
          }))}
          onValueChange={(selectedValue) => {
            return setTimezone(selectedValue);
          }}
          value={timezone}
          placeholderText="Select a Timezone"
        />
      </Grid>

      <Grid item xs={2}></Grid>

      <Grid item xs={1}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleSubmit}
          data-testid="submit-availability"
        >
          SUBMIT
        </Button>
      </Grid>

      <Grid item xs={12}>
        <ScheduleComponent
          ref={scheduleRef}
          data-testid="availability-schedule"
          showQuickInfo={false}
          cellTemplate={CellTemplate}
          renderCell={onCellRender}
          toolbarItems={[
            { name: "Today", visible: false },
            { name: "Previous", visible: true },
            { name: "Next", visible: true },
            { name: "DateRangeText", visible: true },
          ]}
          actionComplete={handleActionComplete}
          selectedDate={new Date(year, month - 1)}
        >
          <ViewsDirective>
            <ViewDirective option="Month" />
          </ViewsDirective>
          <Inject services={[Month]} />
        </ScheduleComponent>
      </Grid>
    </Grid>
  );
};

export default Availability;
