import React from "react";
import {
  Typography,
  Grid,
  Button,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
  Switch,
  CircularProgress,
} from "@mui/material";
import { Formik, Form, Field, FieldProps } from "formik";
import * as Yup from "yup";
import { selectedDateVar } from "../../cache";
import { DatePicker } from "@mui/x-date-pickers";
import { makeStyles } from "@mui/styles";
import { subDays, startOfDay } from "date-fns";
import YupValTooltip from "./YupValTooltip";
import SearchDropdown from "../../helpers/SearchDropdown";
import { GET_TIMEZONES, AVAILABILITY_TYPES } from "../../api/gqlQueries";
import { useQuery } from "@apollo/client";
import ErrorMessage from "../errors/ErrorMessage";

interface TimeZoneObject {
  label: string;
}

interface TimeOffFormProps {
  handleSubmit: (event: any) => void;
  selectedOfficeTimezone: string;
  managerAccess: boolean;
  checkInvalid: (date: Date) => boolean;
  employees: any;
}

interface EmployeeSearchBoxProps {
  errors: any;
  employees: any;
  setFieldValue: (field: string, value: any) => void;
  value: any;
}

// @ts-ignore
const useStyles = makeStyles(() => ({
  dt: {
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: "#EAEAEA",
      color: "#333333",
    },
    "& .MuiButton-iconSizeSmall": {
      color: "rgba(134, 134, 137, 1)",
    },
    "& .MuiButton-label": {
      color: "rgba(134, 134, 137, 1)",
      fontSize: "15px",
    },
    border: "none",
    fontSize: "15px",
  },
  root: {
    margin: 0,
    padding: 0,
  },
  table: {
    border: "none",
    boxSizing: "none",
    boxShadow: "none",
    borderRadius: "none",
    width: "100%",
  },
  deleteButton: {
    color: "#333333",
  },
  headerSpacing: {
    marginTop: 30,
    marginBottom: 20,
  },
  tooltip: {
    backgroundColor: "rgba(57, 57, 60, 0.95)",
  },
  warningIcon: {
    fontSize: 16,
    color: "red",
    marginLeft: 5,
  },
  flexDiv: {
    display: "flex",
    alignItems: "center",
  },
  placeholder: {
    fontStyle: "italic", // Optional: make it italic or add other styles
  },
}));

export default function TimeOffInputs({
  handleSubmit,
  selectedOfficeTimezone,
  managerAccess,
  checkInvalid,
  employees,
}: TimeOffFormProps) {
  const classes = useStyles();
  const date = selectedDateVar();

  const validationSchema = Yup.object().shape({
    employee: managerAccess
      ? Yup.object().required("Please select an employee")
      : Yup.object().nullable(),
    startDate: Yup.date()
      .required("Start date is required")
      .min(
        startOfDay(subDays(new Date(), 1)),
        "Start date cannot be in the past",
      )
      .test(
        "is-valid-start-date",
        "Start date is invalid",
        function (value: any) {
          return !checkInvalid(value);
        },
      ),
    endDate: Yup.date()
      .required("End date is required")
      .min(Yup.ref("startDate"), "End date must be after start date")
      .test("is-valid-end-date", "End date is invalid", function (value: any) {
        return !checkInvalid(value);
      }),
    timezone: Yup.string().required("Timezone is required"),
    isAllDay: Yup.boolean().required(),
    startTime: Yup.string().nullable(),
    endTime: Yup.string()
      .test(
        "is-end-time-valid",
        "End time must be after start time",
        function (this: any, endTime: string | undefined) {
          const { startDate, startTime, endDate, isAllDay } = this.parent;
          if (!startTime || !endTime || isAllDay) {
            return true;
          }

          // Parse date and time into Date objects
          const start = `${startDate.toISOString().split("T")[0]}T${startTime}`;
          const end = `${endDate.toISOString().split("T")[0]}T${endTime}`;

          // Validate if the end time is after the start time
          return new Date(end) > new Date(start);
        },
      )
      .nullable(),
    workHour: Yup.number()
      .required("Work hour is required")
      .min(0, "Work hour cannot be negative"),
    type: Yup.number()
      .typeError("Please select a valid request type")
      .required("Request reason is required")
      .min(1, "Must select a request type option"),
    comment: Yup.string(),
  });

  function determineDefaultTimezone() {
    // set timezone in precedence user tz, office tz, default to GMT
    let timezone = "";
    const userTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (userTz) {
      timezone = userTz;
    } else if (selectedOfficeTimezone) {
      timezone = selectedOfficeTimezone;
    } else {
      timezone = "Etc/GMT";
    }

    return timezone;
  }

  function EmployeeSearchBoxElem({
    errors,
    employees,
    setFieldValue,
    value,
  }: EmployeeSearchBoxProps) {
    if (managerAccess) {
      return (
        <Grid container direction="row" alignContent="center">
          <Grid item xs={12}>
            <InputLabel htmlFor="employee">
              <Typography variant="h6">Employee:</Typography>
            </InputLabel>
          </Grid>

          <Grid item xs={8}>
            <SearchDropdown
              options={employees.map((emp: any) => ({
                ...emp,
                label: emp.name,
              }))}
              placeholderText="Select An Employee"
              onValueChange={(selectedValue: any) => {
                return setFieldValue("employee", selectedValue);
              }}
              value={value?.name}
            />
          </Grid>

          <Grid item xs={4}>
            {errors ? (
              <YupValTooltip
                error={String(errors)}
                tooltipStyle={classes.tooltip}
                warningStyle={classes.warningIcon}
                testid={"employee-warning"}
              />
            ) : null}
          </Grid>
        </Grid>
      );
    } else {
      return null;
    }
  }

  function StartTimeInput({
    errors,
    isAllDay,
  }: {
    errors: any;
    isAllDay: boolean;
  }) {
    if (!isAllDay) {
      return (
        <>
          <InputLabel htmlFor="startTime">
            <Typography variant="h6">Start Time:</Typography>
          </InputLabel>
          <Field
            name="startTime"
            type="time"
            data-testid="startTimeTimeOffFormShift"
          >
            {({ field }: FieldProps) => (
              <TextField
                {...field}
                type="time"
                placeholder="Start Time"
                InputProps={{
                  inputProps: {
                    "data-testid": "start-time-input",
                  },
                }}
              />
            )}
          </Field>
          {errors.startTime ? (
            <YupValTooltip
              error={String(errors.startTime)}
              tooltipStyle={classes.tooltip}
              warningStyle={classes.warningIcon}
              testid={"startTime-warning"}
            />
          ) : null}
        </>
      );
    } else {
      return null;
    }
  }

  function EndTimeInput({
    errors,
    isAllDay,
  }: {
    errors: any;
    isAllDay: boolean;
  }) {
    if (!isAllDay) {
      return (
        <>
          <InputLabel htmlFor="endTime">
            <Typography variant="h6">End Time:</Typography>
          </InputLabel>
          <Field
            name="endTime"
            type="time"
            data-testid="endTimeTimeOffFormShift"
          >
            {({ field }: FieldProps) => (
              <TextField
                {...field}
                type="time"
                placeholder="End Time"
                InputProps={{
                  inputProps: {
                    "data-testid": "end-time-input",
                  },
                }}
              />
            )}
          </Field>
          {errors.endTime ? (
            <YupValTooltip
              error={String(errors.endTime)}
              tooltipStyle={classes.tooltip}
              warningStyle={classes.warningIcon}
              testid={"endTime-warning"}
            />
          ) : null}
        </>
      );
    } else {
      return null;
    }
  }

  const {
    data: tzData,
    loading: tzLoading,
    error: tzError,
  } = useQuery(GET_TIMEZONES);

  const availabilityTypes = useQuery(AVAILABILITY_TYPES);

  if (tzLoading || availabilityTypes.loading) {
    return <CircularProgress color="primary" />;
  }
  if (tzError || availabilityTypes.error) {
    return <ErrorMessage />;
  }
  return (
    <Grid container justifyContent="flex-start">
      <Formik
        enableReinitialize
        initialValues={{
          isAllDay: true,
          employee: "",
          startDate: date,
          endDate: date,
          timezone: determineDefaultTimezone(),
          startTime: "00:00",
          endTime: "00:00",
          workHour: "0",
          type: " ",
          comment: " ",
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, values, handleChange, setFieldValue }) => (
          <Grid item xs={6}>
            <Form style={{ width: "100%" }}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.isAllDay}
                      onChange={() => {
                        setFieldValue("isAllDay", !values.isAllDay);
                      }}
                      name="isAllDay"
                      color={values.isAllDay ? "primary" : "secondary"}
                      data-testid="allDaySwitchTimeOffFormShift"
                    />
                  }
                  label="All Day"
                />
              </Grid>

              <Grid container direction="column" spacing={2}>
                <Grid item xs={8}>
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <EmployeeSearchBoxElem
                        errors={errors.employee}
                        employees={employees}
                        setFieldValue={setFieldValue}
                        value={values.employee}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item container justifyContent="flex-start">
                  <Grid item xs={6}>
                    <Grid item xs={12}>
                      <InputLabel htmlFor="start-date">
                        <Typography variant="h6">Start Date:</Typography>
                      </InputLabel>
                    </Grid>

                    <Grid item xs={8}>
                      <div data-testid="startDateTimeOffFormShift">
                        <DatePicker
                          // value must be ISO 8601 format
                          value={values.startDate}
                          onChange={(e) => {
                            setFieldValue("startDate", e);
                          }}
                          renderInput={(props) => {
                            return (
                              <TextField
                                {...props}
                                id="start-date"
                                onKeyUp={(e) => e.preventDefault()}
                                onKeyDown={(e) => e.preventDefault()}
                                InputProps={{
                                  ...props.InputProps,
                                  inputProps: {
                                    ...props.inputProps,
                                    "data-testid": "start-date-input",
                                  },
                                }}
                              />
                            );
                          }}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={4}>
                      {errors.startDate ? (
                        <YupValTooltip
                          error={String(errors.startDate)}
                          tooltipStyle={classes.tooltip}
                          warningStyle={classes.warningIcon}
                          testid={"startDate-warning"}
                        />
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <StartTimeInput
                      errors={errors}
                      isAllDay={values.isAllDay}
                    />
                  </Grid>
                </Grid>

                <Grid item container justifyContent="flex-start">
                  <Grid container item xs={6}>
                    <Grid item xs={12}>
                      <InputLabel htmlFor="endDate">
                        <Typography variant="h6">End Date:</Typography>
                      </InputLabel>
                    </Grid>

                    <Grid item xs={8}>
                      <DatePicker
                        // value must be ISO 8601 format
                        value={values.endDate}
                        onChange={(e) => {
                          setFieldValue("endDate", e);
                        }}
                        renderInput={(props) => {
                          return (
                            <TextField
                              {...props}
                              id="endDate"
                              onKeyUp={(e) => e.preventDefault()}
                              onKeyDown={(e) => e.preventDefault()}
                              InputProps={{
                                ...props.InputProps,
                                inputProps: {
                                  ...props.inputProps,
                                  "data-testid": "end-date-input",
                                },
                              }}
                            />
                          );
                        }}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      {errors.endDate ? (
                        <YupValTooltip
                          error={String(errors.endDate)}
                          tooltipStyle={classes.tooltip}
                          warningStyle={classes.warningIcon}
                          testid={"endDate-warning"}
                        />
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <EndTimeInput errors={errors} isAllDay={values.isAllDay} />
                  </Grid>
                </Grid>

                <Grid item xs={8}>
                  <Grid container direction="row" alignContent="center">
                    <Grid item xs={12}>
                      <InputLabel htmlFor="timezone">
                        <Typography variant="h6">Timezone:</Typography>
                      </InputLabel>
                    </Grid>

                    <Grid item xs={8}>
                      <SearchDropdown
                        options={tzData?.getTimezones.map(
                          (timezone: string) => ({
                            label: timezone,
                          }),
                        )}
                        value={values.timezone ? values.timezone : null}
                        placeholderText="Select a Timezone"
                        onValueChange={(selectedValue: TimeZoneObject) => {
                          return setFieldValue(
                            "timezone",
                            selectedValue?.label,
                          );
                        }}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      {errors.timezone ? (
                        <YupValTooltip
                          error={String(errors.timezone)}
                          tooltipStyle={classes.tooltip}
                          warningStyle={classes.warningIcon}
                          testid={"timezone-warning"}
                        />
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={8}>
                  <InputLabel htmlFor="type">
                    <Typography variant="h6">Type of request:</Typography>
                  </InputLabel>

                  <Field
                    id="type"
                    name="type"
                    type="number"
                    as={Select}
                    data-testid="requestsTypeTimeOffFormShift"
                  >
                    <MenuItem
                      value=" "
                      disabled
                      className={classes.placeholder}
                    >
                      Select Request Type
                    </MenuItem>
                    {availabilityTypes.data.availabilityTypes.length > 0 ? (
                      availabilityTypes.data.availabilityTypes.map(
                        (timeOffReason: any) => (
                          <MenuItem
                            key={timeOffReason.name + timeOffReason.id}
                            value={timeOffReason.id}
                          >
                            {timeOffReason.name}
                          </MenuItem>
                        ),
                      )
                    ) : (
                      <MenuItem data-testid="types-no-option" value="">
                        No options available
                      </MenuItem>
                    )}
                  </Field>
                  {errors.type ? (
                    <YupValTooltip
                      error={String(errors.type)}
                      tooltipStyle={classes.tooltip}
                      warningStyle={classes.warningIcon}
                      testid={"request-warning"}
                    />
                  ) : null}
                </Grid>

                <Grid item xs={6}></Grid>

                <Grid item xs={6}>
                  <InputLabel htmlFor="workHour">
                    <Typography variant="h6">Hours used:</Typography>
                  </InputLabel>
                  <Field id="workHour" name="workHour" type="number">
                    {({ field }: FieldProps) => (
                      <TextField
                        {...field}
                        type="text"
                        placeholder="Work Hours"
                        InputProps={{
                          inputProps: {
                            "data-testid": "workhoursTimeOffFormShift",
                          },
                        }}
                      />
                    )}
                  </Field>
                  {errors.workHour ? (
                    <YupValTooltip
                      error={String(errors.workHour)}
                      tooltipStyle={classes.tooltip}
                      warningStyle={classes.warningIcon}
                      testid={"workHours-warning"}
                    />
                  ) : null}
                </Grid>

                <Grid item xs={8}>
                  <InputLabel htmlFor="comment">
                    <Typography id="comment" variant="h6">
                      Comment:
                    </Typography>
                  </InputLabel>

                  {errors.comment ? (
                    <YupValTooltip
                      error={String(errors.comment)}
                      tooltipStyle={classes.tooltip}
                      warningStyle={classes.warningIcon}
                      testid={"comment-warning"}
                    />
                  ) : null}
                  <Field
                    id="comment"
                    name="comment"
                    type="text"
                    as={TextField}
                    data-testid="commentTimeOffFormShift"
                  />
                </Grid>

                <Grid item xs={4}></Grid>

                <Grid item xs={4}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    data-testid="saveTimeOffFormShift"
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        )}
      </Formik>
    </Grid>
  );
}
